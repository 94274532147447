import React, { useState, useEffect } from 'react';
import { PortfolioCardData } from 'types/content/portfolio';
import classNames from 'classnames';
import FilterIcon from '@assets/filterIcon.svg';
import { portfolioContent } from '../../content/index';
import PortfolioCardsContainer, {
  SmallCards,
} from '../PortfolioCardsContainer';
import MobileCardSection from './MobileCardSection';
import Image from '../Image';

const OurWork = () => {
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const [filteredLargeCards, setFilteredLargeCards] = useState<
    PortfolioCardData[]
  >([]);
  const [filteredSmallCards, setFilteredSmallCards] = useState<
    PortfolioCardData[]
  >([]);

  const { header, filterIcon, sections, filterText, filterOptions, miniX } =
    portfolioContent;

  useEffect(() => {
    const filterCards = () => {
      const { largeCards, smallCards } = sections;

      if (selectedFilters.length) {
        setFilteredLargeCards(
          largeCards.filter(largeCard => {
            return (
              selectedFilters.includes(largeCard.industry) ||
              selectedFilters.includes(largeCard.projectType)
            );
          }),
        );

        setFilteredSmallCards(
          smallCards.filter(smallCard => {
            return (
              selectedFilters.includes(smallCard.industry) ||
              selectedFilters.includes(smallCard.projectType)
            );
          }),
        );
      } else {
        setFilteredLargeCards(largeCards);
        setFilteredSmallCards(smallCards);
      }
    };
    filterCards();
  }, [selectedFilters, sections]);

  const addRemoveFilter = (value: string) => {
    selectedFilters.includes(value)
      ? setSelectedFilters(
          selectedFilters.filter(filterTag => filterTag !== value),
        )
      : setSelectedFilters(state => [...state, value]);
  };

  const toggleFilterOptions = () => {
    setShowFilterOptions(!showFilterOptions);
  };

  const ShowCards = () => {
    return (
      <div className="flex flex-col space-y-6 mt-4 md:mt-7 md:space-y-9">
        {sections.largeCards.map((card, index) => {
          const smallCardsIndex = index * 4;
          const selectedSmallCards = filteredSmallCards.slice(
            smallCardsIndex,
            smallCardsIndex + 4,
          );
          return (
            <div key={`${card.title}`}>
              <div className={classNames('hidden md:block')}>
                {index < filteredLargeCards.length ? (
                  <PortfolioCardsContainer
                    largeCard={filteredLargeCards[index]}
                    smallCards={selectedSmallCards}
                    type={index % 2 !== 0 ? 'right' : 'left'}
                    baseImagePath="../../portfolio/"
                    smallCardClassName="aspect-w-16 aspect-h-12"
                    // largeCardClassName="aspect-w-16 aspect-h-9"
                  />
                ) : (
                  <div className="w-1/2">
                    <SmallCards
                      cards={selectedSmallCards}
                      baseImagePath="../../portfolio/"
                    />
                  </div>
                )}
              </div>
              <div className="flex flex-col md:hidden">
                <MobileCardSection
                  largeCard={filteredLargeCards[index]}
                  smallCards={selectedSmallCards}
                />
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const FilterOptions = () => {
    return (
      <div className="flex flex-wrap justify-end space-x-2">
        {filterOptions.map(option => {
          return (
            <button
              key={option}
              onClick={() => addRemoveFilter(option)}
              className={classNames(
                'mt-2 border-solid box-border flex items-center border-secondary rounded-xl px-2 text-sm',
                {
                  border: selectedFilters.includes(option),
                },
              )}
            >
              <span>{option}</span>
              <Image
                src={miniX.file}
                alt={miniX.alt}
                className={classNames('ml-4', {
                  'opacity-0': !selectedFilters.includes(option),
                })}
              />
            </button>
          );
        })}
      </div>
    );
  };

  return (
    <div className="flex flex-col md:mb-12">
      <h1 className="text-2xl font-extrabold md:text-4xl w-9/12 md:w-full">
        {header}
      </h1>
      <div className="mt-5 flex flex-col items-end w-300px self-end">
        <button
          className="flex justify-end space-x-3 items-center hidden"
          onClick={() => toggleFilterOptions()}
        >
          <span className="text-sm">{filterText}</span>
          <Image src={FilterIcon} alt={filterIcon.alt} className="" />
        </button>
        {showFilterOptions && <FilterOptions />}
      </div>
      <ShowCards />
    </div>
  );
};

export default OurWork;
