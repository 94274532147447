import { Link } from 'gatsby';
import React from 'react';
import { PortfolioCardData } from 'types/content/portfolio';
import ContentImageTile from '../ContentImageTile';
import Image from '../Image';
import PortfolioCard from '../PortfolioCard';

type BodyPropsType = {
  largeCard?: PortfolioCardData;
  smallCards: PortfolioCardData[];
};

function MobileCardSection({ largeCard, smallCards }: BodyPropsType) {
  return (
    <div className="">
      {largeCard && (
        <Link data-testid="large-card" to={largeCard.link}>
          <ContentImageTile
            text={largeCard.overlay}
            imagePath={`../portfolio/${largeCard.image.file}`}
            alt={largeCard.image.alt}
            className="font-semibold aspect-w-1 aspect-h-1"
            imageClassName="rounded shadow-lg object-cover object-center"
            logoPath={`../portfolio/${largeCard.logo.file}`}
            logoAlt={largeCard.logo.alt}
          />
        </Link>
      )}
      {smallCards.map(item => {
        return (
          <React.Fragment key={item.title}>
            {item.logo.file.length === 0 && (
              <div>
                <Image
                  src={item.image.file}
                  alt={item.image.alt}
                  className="w-full h-200px rounded mt-6 shadow-lg"
                />
              </div>
            )}
            {item.logo.file.length > 0 && (
              <div className="mt-6">
                <div className="">
                  <PortfolioCard
                    className="w-full object-cover rounded shadow-lg aspect-w-16 aspect-h-9"
                    imagePath={`../portfolio/${item.image.file}`}
                    alt={item.image.alt}
                    logoPath={`../portfolio/${item.logo.file}`}
                    logoAlt={item.logo.alt}
                    flipData={item.flipContent}
                  />
                </div>
              </div>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
}

export default MobileCardSection;
