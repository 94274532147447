import * as React from 'react';
import PageSection from '../../components/PageSection';
import OurWork from '../../components/portfolio/OurWork';
import Contact from '../../components/portfolio/Contact';
import content from '../../content/portfolio.yaml';
import DocumentHead from '../../components/DocumentHead';

const PortfolioPage = () => {
  return (
    <div>
      <DocumentHead content={content.metadata} />
      <PageSection
        testId="our-work-container"
        className="mt-7 md:mt-10 lg:mt-9 mx-3 lg:mx-68px md:mx-64px md-900px:pt-9 pt-7"
      >
        <OurWork />
      </PageSection>
      <PageSection testId="button-container" className="my-10 md:my-12 md:mt-0">
        <Contact />
      </PageSection>
    </div>
  );
};

export default PortfolioPage;
